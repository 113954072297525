import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import TransactionList from '../screens/TransactionList';
import { requestGetWithToken, requestDownload } from '../axios';
import { HOMEPAGE } from '../config';


export default function TransactionListContainer(props) {
	let navigate = useNavigate()
	let [ searchParams, setSearchParams ] = useSearchParams()

    async function filterObject(params){
		try {
			return await requestGetWithToken('/filter-object/', params)

		} catch (error) {
			if (error.response.status === 401) {
				window.location.href = `/${HOMEPAGE}login/`
			}
			else {
				return error
			}
		}
	}

    async function getTransaction(params){
		try {
			return await requestGetWithToken('/transaction/list/', params)

		} catch (error) {
			if (error.response.status === 401) {
				window.location.href = `/${HOMEPAGE}login/`
			}
			else {
				return error
			}
		}
	}

	async function getDownload(params){
		try {
			return await requestDownload('/transaction/list/', params)

		} catch (error) {
			return error
		}
	}


    return <TransactionList
        {...props} 
        filterObject={filterObject}
        getTransaction={getTransaction}
		searchParams={searchParams}
		setSearchParams={setSearchParams}
		navigate={navigate}
		getDownload={getDownload}
    />
}





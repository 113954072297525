import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import TransactionList from './containers/TransactionList';
import TransactionDetail from './containers/TransactionDetail';
import TransactionUpload from './containers/TransactionUpload';
import Broker from './containers/Broker';
import Summary from './containers/Summary';
import Login from './containers/Login';
import Logout from './screens/Logout';


class Apps extends Component {
	constructor(props){
		super(props)
		this.state = {
			openMenu: false,
			broker_logo: localStorage.getItem('broker_logo') ? localStorage.getItem('broker_logo') : null
		}
	}

	openMenuAction() {
		this.setState({openMenu: !this.state.openMenu})
	}

	render() {
		return (
			<div className="min-h-screen bg-slate-100">
				<div className="grid grid-cols-2 bg-white items-center px-5 py-2 h-16"> 
					<div className="flex items-center">
						<div className="mr-3"><img className="max-h-12" src={this.state.broker_logo} /></div> 
						<div className="text-gray-800 text-lg">Broker Management</div>
					</div>

					{localStorage.getItem('loginData') ?
						<div className="flex items-center justify-self-end">
							<div className="text-gray-500 mr-3"><FontAwesomeIcon icon={faCircleUser} size='2x' /></div>
							<div>
								<div className="text-gray-500 text-sm">{JSON.parse(localStorage.getItem('loginData'))['email']}</div>
								<div className="text-gray-500 text-sm">{JSON.parse(localStorage.getItem('loginData'))['role']}</div>
							</div>
						</div>
					: null}
				</div>

                <Routes>
                    <Route path={'/transaction/list/'} element={<TransactionList />} />
                    <Route path={'/transaction/detail/'} element={<TransactionDetail />} />
                    <Route path={'/transaction/upload/'} element={<TransactionUpload />} />
                    <Route path={'/broker/list/'} element={<Broker />} />
                    <Route path={'/login/'} element={<Login />} />
                    <Route path={'/logout/'} element={<Logout />} />
                    <Route path={'/'} element={<Summary />} />
                </Routes>
            </div>
		);
	}
}

export default Apps;
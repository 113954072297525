import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default class Layout extends Component {
	constructor(props){
		super(props)
		this.state = {
			openMenu: false,
			logo: localStorage.getItem('group_logo'),
			menu: localStorage.getItem('loginData') ? JSON.parse(localStorage.getItem('loginData'))['navigation'] : null
		}
	}

	openMenuAction() {
		this.setState({openMenu: !this.state.openMenu})
	}

	render() {
		return (
			<>
				{this.state.menu ?
					<>
						<div className="hidden md:block basis-1/6 bg-gray-800 p-5">
							{this.state.menu.map(item => {
								return (
									<Link key={item.slug}
										className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
										to={item.url}>{item.name}
									</Link>
								)
							})}
						</div>
						
						{this.state.openMenu ?
							<div className="bg-gray-800 p-5 md:hidden">
								{this.state.menu.map(item => {
									return (
										<Link key={item.slug}
											className="block text-white p-3 text-lg active:bg-gray-100 hover:bg-gray-100 hover:text-gray-800"
											to={item.url}>{item.name}
										</Link>
									)
								})}
							</div>
						: null}

						<div className="p-5 text-left md:hidden" onClick={() => this.openMenuAction()}> 
							<FontAwesomeIcon icon={faBars} /> Menu
						</div> 
					</>
				: null}
			</>
		)
	}
}

import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
var numeral = require('numeral');


export function PieChart(props) {
	let [chartData, setChartData] = useState(props.data[0])

	function generateData() {
		return {
			labels: chartData.data.map(item => item.name),
			datasets: [
				{
				  label: chartData.label,
				  data: chartData.data.map(item => item[chartData.object]),
				},
			]
		}
	}

	useEffect(() => {
		setChartData(props.data[0])
	}, [props.data])

	return (
		<>
			<Chart
				type='pie'
				data={generateData()}
				plugins={[ChartDataLabels]}
				options={{
					plugins: {
						legend: {
							display: props.showLegend,
							position: 'top',
							align: 'center',
							labels: {
								font: {
									size: 10
								}
							}
						},
						title: {
							display: true,
							text: props.title
						},
						datalabels: {
							align: 'end',
							anchor: 'center',
							color: '#000',
							formatter: function (value, context) {
								return `${numeral(100 * value / context['dataset']['data'].reduce((a, b) => a + b, 0)).format('0.00')}%`
							},
						}
					}
				}} />
			
			<select className="w-full mt-5 mb-3 text-sm text-gray-500" onChange={e => setChartData(props.data[e.target.value])}>
				{props.data.map((item, n) => {
					return (
						<option key={item.label} value={n}>{item.label}</option>
					)
				})}	
			</select>

			{props.showTable ? 
				<table className="table-fixed w-full">
					<tbody>
						{chartData.data.map((item, n) => {
							return (
								<tr key={item.name}>
									<td className={`${ props.textSize?props.textSize : 'text-sm' } text-gray-500 font-semibold px-2 py-1 truncate`}>{item.name}</td>
									<td className={`${ props.textSize?props.textSize : 'text-sm' } text-gray-500 font-semibold px-2 py-1`}>
										{chartData.type === 'money' ?
											<div>Rp <span className="float-right">{numeral(item[chartData.object]).format('0,00')}</span></div>
										: chartData.type === 'str' ?
											item[chartData.object]
										:null }
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			: null}
		</>
	)
}

export function BarChart(props) {
	let [chartData, setChartData] = useState(props.data[0])

	function generateData() {
		return {
			labels: chartData.data.map(item => item.name),
			datasets: [
				{
				  data: chartData.data.map(item => item[chartData.object]),
				},
			]
		}
	}

	useEffect(() => {
		setChartData(props.data[0])
	}, [props.data])

	return (
		<>
			<select className="w-full mt-5 mb-3 text-sm text-gray-500 border" onChange={e => setChartData(props.data[e.target.value])}>
				{props.data.map((item, n) => {
					return (
						<option key={item.label} value={n}>{item.label}</option>
					)
				})}	
			</select>

			<Chart
				type='bar'
				data={generateData()}
				plugins={[ChartDataLabels]}
				options={{
					plugins: {
						legend: {
							display: props.showLegend,
							position: 'top',
							align: 'center',
							labels: {
								font: {
									size: 10
								}
							}
						},
						title: {
							display: true,
							text: props.title
						},
					}
				}} />
		</>
	)
}

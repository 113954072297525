import React from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../screens/Login';
import { requestPost } from '../axios';

export default function LoginContainer(props) {
	let navigate = useNavigate()

	async function getOtp(data){
		try {
			return await requestPost('/otp/', data)

		} catch (error) {
			return error
		}
	}

	async function loginUser(data){
		try {
			return await requestPost('/login/', data)

		} catch (error) {
			return error
		}
	}

	return <Login 
		{...props} 
		navigate={navigate}
		getOtp={getOtp}
		loginUser={loginUser} />
}
import React, { Component } from 'react'
import { HOMEPAGE } from '../config'

export default class Logout extends Component {
	componentDidMount() {
		localStorage.removeItem('loginData')
		localStorage.removeItem('client')
		localStorage.removeItem('client_logo')
		localStorage.removeItem('broker')
		localStorage.removeItem('broker_logo')
		localStorage.removeItem('token')
		window.location.href = `/${HOMEPAGE}login/`
	}

	render() {
		return (
			<>

			</>
		)
	}
}

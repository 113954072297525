import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import TransactionUpload from '../screens/TransactionUpload';
import { requestGetWithToken, requestTransactionUpload } from '../axios';
import { HOMEPAGE } from '../config';


export default function TransactionUploadContainer(props) {
	let navigate = useNavigate()
	let [ searchParams, setSearchParams ] = useSearchParams()

    async function filterObject(params){
		try {
			return await requestGetWithToken('/filter-object/', params)

		} catch (error) {
			if (error.response.status === 401) {
				window.location.href = `/${HOMEPAGE}login/`
			}
			else {
				return error
			}
		}
	}

	async function uploadTemplate(params){
		try {
			return await requestTransactionUpload('/transaction/upload/', params)

		} catch (error) {
			if (error.response.status === 401) {
				window.location.href = `/${HOMEPAGE}login/`
			}
			else {
				return error
			}
		}
	}


    return <TransactionUpload
        {...props} 
        filterObject={filterObject}
		searchParams={searchParams}
		setSearchParams={setSearchParams}
		navigate={navigate}
		uploadTemplate={uploadTemplate}
    />
}





import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Broker from '../screens/Broker';
import { requestGetWithToken } from '../axios';
import { HOMEPAGE } from '../config';


export default function BrokerContainer(props) {
	let [ searchParams, setSearchParams ] = useSearchParams()

    async function getBrokerList(){
		try {
			return await requestGetWithToken('/broker-list/')

		} catch (error) {
			if (error.response.status === 401) {
				window.location.href = `/${HOMEPAGE}login/`
			}
			else {
				return error
			}
		}
	}


    return <Broker
        {...props} 
        getBrokerList={getBrokerList}
		searchParams={searchParams}
		setSearchParams={setSearchParams}
    />
}





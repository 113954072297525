import React, { Component } from 'react'
import DashboardLayout from '../components/DashboardLayout'
import { Loader, PageHeader, SelectInput } from '../components/utils';
import { PieChart, BarChart } from '../components/charts';
var moment = require('moment');
var numeral = require('numeral');


export default class Summary extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			filter: null,
			broker: localStorage.getItem('broker') ? localStorage.getItem('broker') : 1,
			client: localStorage.getItem('client') !== 'null' ? localStorage.getItem('client') : null,
			year: moment().format('YYYY')
		}
	}

	componentDidMount() {
		this.getSummary()
		this.getFilterObject()
	}

	getSummary() {
		let params = {'broker': this.state.broker, 'year': this.state.year}

		if (this.state.client) {
			params['client'] = this.state.client
		}

		this.props.getSummary(params).then(response => {
			if (response.data.code === '00') {
				this.setState({data: response.data.data})
			}
		})
	}

	getFilterObject() {
		const params = {'broker': this.state.broker, 'type': 'DASHBOARD'}

		this.props.filterObject(params).then(response => {
			if (response.data.code === '00') {
				this.setState({filter: response.data.data})
			}
		})
	}

	render() {
		return (
			<div className="md:flex min-h-screen">
				<DashboardLayout />
				
				<div className="flex-1 md:basis-5/6 p-5">
					{!this.state.data ?
						<Loader />
						:
						<>
							<PageHeader text='Summary' />
							
							<div className="bg-white rounded p-3 mb-5">
								{!this.state.filter ?
										<Loader />
									:
										<form onSubmit={e => this.handleSubmit(e)}>
											<div className="grid md:grid-cols-4 gap-3">
												<SelectInput 
													name='year'
													label='Year'
													required={false} 
													data={this.state.filter.year}  
													value={this.state.year}
													onChange={e => {
														this.setState({[e.target.name]: e.target.value}, () => this.getSummary())
													}} />
												
												<SelectInput 
													name='client'
													label='Client Name'
													required={false} 
													data={this.state.filter.client}  
													value={this.state.client}
													onChange={e => {
														this.setState({[e.target.name]: e.target.value}, () => this.getSummary())
													}} />
												
												{/* <SelectInput 
													name='insurance'
													label='Insurance Name'
													required={false} 
													data={this.state.filter.insurance}  
													value={this.state.insurance}
													onChange={e => this.setState({[e.target.name]: e.target.value})} /> */}
											</div>
											{/* <div className="flex space-x-3 justify-between md:justify-end mt-5">
												<SubmitButton label="Search" />
											</div> */}
										</form>	
								}
							</div>
                            <div className="flex gap-5">
								<div className="flex-1 bg-white rounded p-5 mb-5">
									<div className="text-md text-gray-500 mb-3">Today</div>
									<div className="text-xl font-medium text-gray-500">{this.state.data.summary.actual.today_count} Transactions</div>
									<div className="text-xl font-medium text-gray-500">Rp {numeral(this.state.data.summary.actual.today_premium).format()}</div>
								</div>
								<div className="flex-1 bg-white rounded p-5 mb-5">
									<div className="text-md text-gray-500 mb-3">This Month</div>
									<div className="text-xl font-medium text-gray-500">{this.state.data.summary.actual.month_count} Transactions</div>
									<div className="text-xl font-medium text-gray-500">Rp {numeral(this.state.data.summary.actual.month_premium).format()}</div>
								</div>
								<div className="flex-1 bg-white rounded p-5 mb-5">
									<div className="text-md text-gray-500 mb-3">This Year</div>
									<div className="text-xl font-medium text-gray-500">{this.state.data.summary.actual.year_count} Transactions</div>
									<div className="text-xl font-medium text-gray-500">Rp {numeral(this.state.data.summary.actual.year_premium).format()}</div>
								</div>
							</div>
							<div className="flex gap-5">
								<div className="flex-1 bg-white rounded p-5 mb-5">
									<div className="text-md text-gray-500 mb-3">Total Sum Insured</div>
									<div className="text-xl font-medium text-gray-500">Rp {numeral(this.state.data.summary.main.total_tsi).format()}</div>
								</div>
								<div className="flex-1 bg-white rounded p-5 mb-5">
									<div className="text-md text-gray-500 mb-3">Total Premium</div>
									<div className="text-xl font-medium text-gray-500">Rp {numeral(this.state.data.summary.main.total_premi).format()}</div>
								</div>
								<div className="flex-1 bg-white rounded p-5 mb-5">
									<div className="text-md text-gray-500 mb-3">Total Transaction</div>
									<div className="text-xl font-medium text-gray-500">{this.state.data.summary.main.count}</div>
								</div>
							</div>
							
							<div className="grid grid-cols-2 gap-3 mb-5">
								<div className="bg-white p-3 rounded">
									<BarChart
										data={[
											{'label': 'Premium', 'data': this.state.data.chart.transaction, 'object': 'premium', 'type': 'money'},
											{'label': 'No Transaction', 'data': this.state.data.chart.transaction, 'object': 'count', 'type': 'str'},
											{'label': 'Total Sum Insured', 'data': this.state.data.chart.transaction, 'object': 'tsi', 'type': 'money'},
										]}
										showTable={true}
										showLegend={false}
										textSize='text-xxs'
									/>
								</div>

								<div className="grid grid-cols-2 gap-3">
									<div className="bg-white p-3 rounded">
										<PieChart
											data={[
												{'label': 'Premium', 'data': this.state.data.chart.client, 'object': 'premium', 'type': 'money'},
												{'label': 'No Transaction', 'data': this.state.data.chart.client, 'object': 'count', 'type': 'str'},
												{'label': 'Total Sum Insured', 'data': this.state.data.chart.client, 'object': 'tsi', 'type': 'money'},
											]}
											showTable={true}
											showLegend={false}
											textSize='text-xxs'
											title="Client"
										/>
									</div>
									{!this.state.client ?
										<div className="bg-white p-3 rounded">
											<PieChart
												data={[
													{'label': 'Premium', 'data': this.state.data.chart.insurance, 'object': 'premium', 'type': 'money'},
													{'label': 'Total Sum Insured', 'data': this.state.data.chart.insurance, 'object': 'tsi', 'type': 'money'},
													{'label': 'No Transaction', 'data': this.state.data.chart.insurance, 'object': 'count', 'type': 'str'}
												]}
												showTable={true}
												showLegend={false}
												textSize='text-xxs'
												title="Insurance"
											/>
										</div>
									: null}
								</div>
							</div>
						</>
					}
				</div>
			</div>
		)
	}
}

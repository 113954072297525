import axios from 'axios';
import { API_URL } from './config';

export function requestGet(endpoint, params=null){
	let config = {
		method: 'get',
		url: API_URL + endpoint,
		params: params,
	}
	return axios(config)
}

export function requestPost(endpoint, data){
	let config = {
		method: 'post',
		url: API_URL + endpoint,
		data: data
	}
	return axios(config)
}

export function requestGetWithToken(endpoint, params=null){
	let config = {
		method: 'get',
		url: API_URL + endpoint,
		params: params,
		headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
	}
	return axios(config)
}


export function requestPostWithToken(endpoint, data){
	let config = {
		method: 'post',
		url: API_URL + endpoint,
		data: data,
		headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
	}
	return axios(config)
}

export function requestDownload(endpoint, params=null){
	let config = {
		method: 'get',
		responseType: 'blob',
		url: API_URL + endpoint,
		params: params,
		headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
	}
	return axios(config)
}

export function requestTransactionUpload(endpoint, data){
	let config = {
		method: 'post',
		url: API_URL + endpoint,
		data: data,
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `Bearer ${localStorage.getItem('token')}`
		}
	}
	return axios(config)
}
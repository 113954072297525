import React, { Component } from 'react'
import DashboardLayout from '../components/DashboardLayout'
import { Loader, PageHeader } from '../components/utils'
import { HOMEPAGE } from '../config'

export default class Broker extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			broker: localStorage.getItem('broker') ? localStorage.getItem('broker') : 1,
		}
	}

	componentDidMount() {
		this.getBrokerList()
	}

	getBrokerList() {
		this.props.getBrokerList().then(response => {
			console.log(response.data)
			if (response.data.code === '00') {
				this.setState({data: response.data.data})
			}
		})
	}

	setLocalData(item) {
		localStorage.setItem('broker', item.id)
		localStorage.setItem('broker_logo', item.image)
		this.setState({broker: item.id})
		window.location.href = `/${HOMEPAGE}`
	}

	render() {
		return (
			<div className="md:flex min-h-screen">
				<DashboardLayout />
				
				<div className="flex-1 md:basis-5/6 p-5">
					{!this.state.data ?
						<Loader />
						:
						<>
							<PageHeader text='Broker List' />

							<div className="grid grid-cols-3 gap-5 md:grid-cols-6">
								{this.state.data.map(item => {
									return (
										<button key={item.id}
											className={this.state.broker === item.id.toString() ? "bg-red-500 p-5 rounded" : "bg-gray-300 p-5 rounded"} 
											onClick={() => this.setLocalData(item)}>
												<div className={this.state.broker === item.id.toString() ? "text-white text-center" : "text-gray text-center"}>{ item.name }</div>
										</button>
									)
								})}
							</div>
						</>
					}
				</div>
			</div>
		)
	}
}

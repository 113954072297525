import React, { Component } from 'react'
import { Loader } from '../components/utils'
import { PageHeader, SelectInput, TextInput, SubmitButton, SecondaryButton, DateInput, statusTag, Pagination } from '../components/utils'
import DashboardLayout from '../components/DashboardLayout'
import fileDownload from 'js-file-download';
var moment = require('moment');
var numeral = require('numeral');


export default class TransactionList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: null,
            data: null,
			summary: null,
			broker: localStorage.getItem('broker') ? localStorage.getItem('broker') : 1,
			client: localStorage.getItem('client') !== 'null' ? localStorage.getItem('client') : null,
			page: 1,
			total: 0,
			no_of_pages: 0,
			data_per_page: 0,
        }
    }

    componentDidMount() {
        this.getFilterObject()
        this.getTransaction()
    }

	getAutoFilled(){
		let state = {}
		for (let key of this.props.searchParams.keys()) {
			state[[key]] = this.props.searchParams.get(key)
		}
		this.setState(state)
	}

    getFilterObject() {
        const params = {'broker': this.state.broker, 'type': 'TRANSACTION_LIST'}

        this.props.filterObject(params).then(response => {
            if (response.data.code === '00') {
                this.setState({filter: response.data.data}, () => this.getAutoFilled())
            }
        })
    }

    getTransaction() {
        let params = {'broker': this.state.broker, 'page': this.state.page}

		if (this.state.client) {
			params['client'] = this.state.client
		}

		for (let x of this.props.searchParams.keys()) {
			params[x] = this.props.searchParams.get(x)
		}

        this.props.getTransaction(params).then(response => {
            if (response.data.code === '00') {
                this.setState({
					data: response.data.data.data,
					summary: response.data.data.summary,
					total: response.data.data.count_data,
					no_of_pages: response.data.data.no_of_pages,
					data_per_page: response.data.data.data_per_page,
				})
            }
        })
    }

	async handleSubmit(e) {
		e.preventDefault()

		let params = {'broker': this.state.broker, 'page': this.state.page}
		
		if (this.state.client) {
			params['client'] = this.state.client
		}
		
		for (const x of e.target) {
			params[x.name] = x.value
		}
		
		await this.props.setSearchParams(params)
		this.getTransaction()
	}

	handleDownload(e) {
		let params = {'broker': this.state.broker, 'page': 1, 'download': true}

		for (let x of this.props.searchParams.keys()){
			params[x] = this.props.searchParams.get(x)
		}

		this.props.getDownload(params).then(response => {
			fileDownload(response.data, `transaction_list_${moment().format('x')}.xlsx`);
		})
	}

    render() {
        return (
            <div className="md:flex min-h-screen">
				<DashboardLayout />

				<div className="flex-1 md:basis-5/6 p-5">
					{!this.state.data ?
						<Loader />
						:
						<>
							<PageHeader text='Transaction List' />

                            {!this.state.filter ?
                                <Loader />
                                :
                                <div className="bg-white rounded p-3 mb-5">
                                    <form id="filterForm" autoComplete="off" onSubmit={e => this.handleSubmit(e)}>
                                        <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                                            <SelectInput 
                                                name="client" 
                                                label="Client Name"
                                                required={false} 
                                                data={this.state.filter.client}  
                                                value={this.state.client}
                                                onChange={e => this.setState({[e.target.name]: e.target.value})} />

                                            <SelectInput 
                                                name='insurance'
                                                label='Insurance Name'
                                                required={false} 
                                                data={this.state.filter.insurance}  
                                                value={this.state.insurance}
                                                onChange={e => this.setState({[e.target.name]: e.target.value})} />

                                            <SelectInput 
                                                name='product'
                                                label='Product Name'
                                                required={false} 
                                                data={this.state.filter.product} 
                                                value={this.state.product}
                                                onChange={e => this.setState({[e.target.name]: e.target.value})} />
                                                
                                            <TextInput 
                                                name='order_code' 
                                                label='Order Code'
                                                required={false} 
                                                value={this.state.order_code}
                                                onChange={e => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <TextInput 
                                                name='ref' 
                                                label='Ref'
                                                required={false} 
                                                value={this.state.ref}
                                                onChange={e => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <TextInput 
                                                name='insured' 
                                                label='Insured Name'
                                                required={false} 
                                                value={this.state.insured}
                                                onChange={e => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <DateInput
                                                name='start_from'
                                                label='Periode Start From'
                                                required={false}
                                                maxDate={moment()}
												minDate={moment().subtract(2, 'y')}
												value={this.state.start_from}
												onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <DateInput
                                                name='start_to'
                                                label='Periode Start To'
                                                required={false}
                                                maxDate={moment()}
												minDate={moment().subtract(2, 'y')}
												value={this.state.start_to}
												onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <DateInput
                                                name='end_from'
                                                label='Periode End From'
                                                required={false}
                                                maxDate={moment()}
												minDate={moment().subtract(2, 'y')}
												value={this.state.end_from}
												onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <DateInput
                                                name='end_to'
                                                label='Periode End To'
                                                required={false}
                                                maxDate={moment()}
												minDate={moment().subtract(2, 'y')}
												value={this.state.end_to}
												onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <DateInput
                                                name='created_at_from'
                                                label='Created At From'
                                                required={false}
                                                maxDate={moment()}
												minDate={moment().subtract(2, 'y')}
												value={this.state.created_at_from}
												onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                                            
                                            <DateInput
                                                name='created_at_to'
                                                label='Created At To'
                                                required={false}
                                                maxDate={moment()}
												minDate={moment().subtract(2, 'y')}
												value={this.state.created_at_to}
												onChange={(e) => this.setState({[e.target.name]: e.target.value})} />
                                            
                                        </div>
                                        <div className="flex space-x-3 justify-between md:justify-end mt-5">
                                            <SubmitButton label='Search' />
                                            <SecondaryButton label='Download' onClick={() => this.handleDownload()}/>
                                        </div>
                                    </form>
                                </div>
                            }

							{this.state.summary ?
								<div className="flex gap-5">
									<div className="flex-1 bg-white rounded p-5 mb-5">
										<div className="text-md text-gray-500 mb-3">Total Sum Insured</div>
										<div className="text-xl font-medium text-gray-500">Rp {numeral(this.state.summary.total_tsi).format()}</div>
									</div>
									{!this.state.client ?
										<div className="flex-1 bg-white rounded p-5 mb-5">
											<div className="text-md text-gray-500 mb-3">Total Premium</div>
											<div className="text-xl font-medium text-gray-500">Rp {numeral(this.state.summary.total_premi).format()}</div>
										</div>
									: null}
									<div className="flex-1 bg-white rounded p-5 mb-5">
										<div className="text-md text-gray-500 mb-3">Total Transaction</div>
										<div className="text-xl font-medium text-gray-500">{this.state.summary.count}</div>
									</div>
								</div>
							: null}

                            <div className="bg-white rounded p-5 mb-5">
								{this.state.no_of_pages > 1 ?
									<Pagination 
										pages={this.state.no_of_pages}  
										total={this.state.count_data}
										perPage={this.state.data_per_page}
										onClick={e => console.log(e)} />
								: null}

								<div className="overflow-x-scroll">
									<table className="w-full">
										<thead>
											<tr>
												<th className="p-3 border-2">No</th>
												<th className="p-3 border-2">Order Code</th>
												<th className="p-3 border-2">Client</th>
												<th className="p-3 border-2">Insurance</th>
												<th className="p-3 border-2">Product</th>
												<th className="p-3 border-2">Total Sum Insured</th>
												<th className="p-3 border-2">Premium</th>
												<th className="p-3 border-2">Period</th>
												<th className="p-3 border-2">Created At</th>
												<th className="p-3 border-2">Status</th>
											</tr>
										</thead>
										<tbody>
										{this.state.data.map((item, n)  => {
											return (
												<tr key={n.toString()} className="hover:bg-gray-100">
													<td className="text-sm text-center text-gray-500 p-3 border-2">{n + 1}</td>
													<td className="text-sm text-center text-gray-500 p-3 border-2">
                                                        <a href={`../../transaction/detail/?broker=${this.state.broker}&order_code=${item.order_code}`} target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-800">{ item.order_code }</a>
                                                    </td>
													<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.client_name }</td>
													<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.insurance_name }</td>
													<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.product_name }</td>
													<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{numeral(item.tsi).format()}</span></td>
													<td className="text-sm text-left text-gray-500 p-3 border-2">Rp <span className="float-right">{numeral(item.nominal).format()}</span></td>
													<td className="text-sm text-center text-gray-500 p-3 border-2">
                                                        <div>{ item.start_date }</div>
                                                        <div>{ item.end_date }</div>
                                                    </td>
													<td className="text-sm text-center text-gray-500 p-3 border-2">{ item.created_at }</td>
													<td className="text-sm text-center text-gray-500 p-3 border-2">{statusTag(item.status)}</td>
												</tr>
											)
										})}
										</tbody>
									</table>
								</div>
							</div>
                        </>
                    }
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import { Loader, PageHeader, TextInput, TextArea } from '../components/utils'
import DashboardLayout from '../components/DashboardLayout'
var numeral = require('numeral');


export default class TransactionDetail extends Component {
	constructor(props) {
        super(props)
        this.state = {
            data: null,
			broker: localStorage.getItem('broker') ? localStorage.getItem('broker') : 1,
			client: localStorage.getItem('client'),
        }
    }

	componentDidMount() {
		this.getTransactionDetail()
	}

	getTransactionDetail() {
		const params = {'broker': this.state.broker, 'order_code': this.props.searchParams.get('order_code')}

		this.props.getTransactionDetail(params).then(response => {
			if (response.data.code === '00') {
				this.setState({data: response.data.data})
			}
		})
	}

	render() {
		return (
			<div className="md:flex min-h-screen">
				<DashboardLayout />

				<div className="flex-1 md:basis-5/6 p-5">
					{!this.state.data ?
						<Loader />
						:
						<>
							<PageHeader text='Transaction Detail' />

							<div className="grid grid-cols-2 gap-5">
								<div className="bg-white rounded p-5 mb-5">
									<div className="text-xl font-medium text-gray-500 mb-5">Policy Detail</div>
									<div className="grid grid-cols-1 gap-3">
										<TextInput 
											name='order_code' 
											label='Order Code'
											disabled={true} 
											value={this.state.data.order_code} />
										
										<TextInput 
											name='ref' 
											label='Ref'
											disabled={true} 
											value={this.state.data.ref} />

										<TextInput 
											name='client_name' 
											label='Client Name'
											disabled={true} 
											value={this.state.data.client_name} />
										
										<TextInput 
											name='category_name' 
											label='Line of Business'
											disabled={true} 
											value={this.state.data.category_name} />
										
										<TextInput 
											name='insurance_name' 
											label='Insurance Name'
											disabled={true} 
											value={this.state.data.insurance_name} />
										
										<TextInput 
											name='product_name' 
											label='Product Name'
											disabled={true} 
											value={this.state.data.product_name} />
										
										<TextArea 
											name='product_desc' 
											label='Product Description'
											disabled={true} 
											value={this.state.data.product_desc} />

										<TextArea 
											name='product_detail' 
											label='Product Detail'
											disabled={true} 
											rows={10}
											value={this.state.data.product_detail} />
									</div>
								</div>
								<div className="bg-white rounded p-5 mb-5">
									<div className="text-xl font-medium text-gray-500 mb-5">Insured Detail</div>
									<div className="grid grid-cols-1 gap-3">
										<TextInput 
											name='fullname' 
											label='Fullname'
											disabled={true} 
											value={this.state.data.fullname} />
										
										<TextInput 
											name='tsi' 
											label='Total Sum Insured'
											disabled={true} 
											value={numeral(this.state.data.tsi).format()} />
										
										<TextInput 
											name='nominal' 
											label='Premium'
											disabled={true} 
											value={numeral(this.state.data.nominal).format()} />
										
										<TextInput 
											name='start_date' 
											label='Start Date'
											disabled={true} 
											value={this.state.data.start_date} />
										
										<TextInput 
											name='end_date' 
											label='End Date'
											disabled={true} 
											value={this.state.data.end_date} />
										
										<TextInput 
											name='status' 
											label='Status'
											disabled={true} 
											value={this.state.data.status} />
										
										<TextArea 
											name='remarks' 
											label='Remarks'
											disabled={true} 
											value={this.state.data.remarks} />
										
										<>
											<div className="form-label text-sm text-gray-500">E-Policy</div>
											<div className="text-sm text-blue-500 hover:text-blue-800">
												<a href={this.state.data.epolicy} target="_blank">{this.state.data.epolicy}</a>
											</div>
										</>
									</div>
								</div>
							</div>
						</>
					}
				</div>
			</div>
		)
	}
}

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Summary from '../screens/Summary';
import { requestGetWithToken } from '../axios';
import { HOMEPAGE } from '../config';


export default function SummaryContainer(props) {
	let [ searchParams, setSearchParams ] = useSearchParams()

    async function getSummary(params){
		try {
			return await requestGetWithToken('/summary/', params)

		} catch (error) {
			if (error.response.status === 401) {
				window.location.href = `/${HOMEPAGE}login/`
			}
			else {
				return error
			}
		}
	}

	async function filterObject(params){
		try {
			return await requestGetWithToken('/filter-object/', params)

		} catch (error) {
			if (error.response.status === 401) {
				window.location.href = `/${HOMEPAGE}login/`
			}
			else {
				return error
			}
		}
	}

    return <Summary
        {...props} 
        getSummary={getSummary}
		filterObject={filterObject}
		searchParams={searchParams}
		setSearchParams={setSearchParams}
    />
}





import React, { Component } from 'react';
import { TextInput } from '../components/utils';
import { HOMEPAGE } from '../config';

export default class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showOtpField: false,
			timer: 0
		}
	}

	getOtp() {
		let data = new FormData()
		data.append('identification', this.state.identification)
		
		this.props.getOtp(data).then(response => {
			if (response.data.code === '00') {
				this.setState({showOtpField: true})
				this.countDown()
			}
			else {
				alert(response.data.message)
			}
		})
	}

	loginUser(e) {
		e.preventDefault()
		
		let data = new FormData()
		for (const x of e.target) {
			data.append(x.name, x.value)
		}

		this.props.loginUser(data).then(response => {
			if (response.data.code === '00') {
				localStorage.setItem('loginData', JSON.stringify(response.data.data))
				localStorage.setItem('client', response.data.data.client_id)
				localStorage.setItem('client_logo', response.data.data.client_logo)
				localStorage.setItem('broker', response.data.data.broker_id)
				localStorage.setItem('broker_logo', response.data.data.broker_logo)
				localStorage.setItem('token', response.data.data.token)
				window.location.href = `/${HOMEPAGE}`
			}
			else {
				alert(response.data.message)
			}
		})
	}

	countDown() {
		this.setState({timer: 60})

		let id = setInterval(() => {
			if (this.state.timer - 1 >= 0) {
				this.setState({timer: this.state.timer - 1})
			}
			else {
				clearInterval(id)
			}
		}, 1000)
	}

	render() {
		return (
			<div className="mt-20 mx-auto max-w-md">
				<form id="loginForm" onSubmit={e => this.loginUser(e)}>
					<div className="grid grid-cols-3 mb-3 space-x-3">
						<div className="col-span-2 space-y-3">
							<TextInput 
								name='identification'
								placeholder='Email'
								value={this.state.identification}
								required={true}
								onChange={e => this.setState({[e.target.name]: e.target.value})} />

							{this.state.showOtpField ?
								<TextInput 
									name="otp"
									placeholder="OTP Code"
									value={this.state.otp}
									required={true}
									onChange={e => this.setState({[e.target.name]: e.target.value})} />
							: null}
						</div>
						<div className="flex-none">
							<button type="button" 
								className="p-2 w-100 rounded w-full text-white bg-green-500 hover:bg-green-800 disabled:bg-gray-400"
								onClick={() => this.getOtp()} disabled={this.state.timer > 0 ? true : false}>{this.state.timer > 0 ? this.state.timer : 'OTP'}</button>
						</div>
					</div>

					{this.state.showOtpField ?
						<div className="mt-10">
							<input type="submit" className="p-2 rounded w-full text-white bg-red-500 hover:bg-red-800" value="LOGIN" />
						</div>
					: null}
				</form>
			</div>
		)
	}
}

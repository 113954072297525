import React, { Component } from 'react'
import { Loader } from '../components/utils'
import { PageHeader, SelectInput, SubmitButton, SecondaryButton, FileInput } from '../components/utils'
import DashboardLayout from '../components/DashboardLayout'
import { HOMEPAGE, TEMPLATE_URL } from '../config';
var numeral = require('numeral');


export default class TransactionUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: null,
            data: null,
			loadingUpload: false,
			loadingConfirm: false,
			broker: localStorage.getItem('broker') ? localStorage.getItem('broker') : 1,
			client: localStorage.getItem('client') !== 'null' ? localStorage.getItem('client') : null,
        }
		this.formRef = React.createRef();
		this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getFilterObject()
    }

    getFilterObject() {
        const params = {'broker': this.state.broker, 'client': this.state.client, 'type': 'TRANSACTION_UPLOAD'}

        this.props.filterObject(params).then(response => {
            if (response.data.code === '00') {
                this.setState({filter: response.data.data})
            }
        })
    }

	handleSubmit(e) {
		e.preventDefault()

		this.setState({loadingUpload: true})

		let formdata = {
			'broker_id': this.state.broker,
			'broker_product_id': e.target['broker_product_id'].value,
			'file': e.target['file'].files[0],
			'is_confirm': 0
		}

		this.props.uploadTemplate(formdata).then(response => {
			if (response.data.code === '00') {
                this.setState({data: response.data.data})
            }
			else {
				alert(response.data.message)
			}

			this.setState({loadingUpload: false})
		})
	}

	handleConfirmSubmit() {
		this.setState({loadingConfirm: true})

		let formdata = {
			'broker_id': this.state.broker,
			'broker_product_id': this.state.broker_product_id,
			'file':this.state.file.files[0],
			'is_confirm': 1
		}

		this.props.uploadTemplate(formdata).then(response => {
			if (response.data.code === '00') {
                window.location.href = `/${HOMEPAGE}transaction/list/`
            }
			else {
				alert(response.data.message)
			}
		})
	}
	
    render() {
        return (
            <div className="md:flex min-h-screen">
				<DashboardLayout />

				<div className="md:basis-5/6 p-5 overflow-hidden">
					<PageHeader text='Transaction Upload' />
						{!this.state.filter ?
							<Loader />
							:
							<div className="bg-white rounded p-3 mb-5">
								<form id="filterForm" autoComplete="off" onSubmit={this.handleSubmit} ref={this.formRef}>
									<div className="grid grid-cols-1 md:grid-cols-3 gap-3">
										<SelectInput 
											name="broker_product_id" 
											label="Product"
											required={false} 
											data={this.state.filter.broker_product}  
											value={this.state.broker_product_id}
											onChange={e => this.setState({[e.target.name]: e.target.value})} />

										<FileInput 
											name='file'
											label='Upload Template'
											required={true}
											onChange={(e) => this.setState({[e.target.name]: e.target})} />

										<div className="flex pt-5 gap-3">
											{this.state.loadingUpload ?
												<Loader />
											:
												<SubmitButton label='Upload' />
											}
											
                                            <a href={TEMPLATE_URL} className='p-2 rounded max-w-36 text-gray-500 bg-slate-200'>Download Template</a>
										</div>
									</div>
								</form>
							</div>
						}

						{this.state.data ?
							<div className="bg-white rounded p-5 mb-5">
								<div className="mb-5 text-right">
									{this.state.loadingConfirm ?
										<Loader />
									:
										<SecondaryButton 
											label="Konfirmasi Upload"
											onClick={() => this.handleConfirmSubmit()}
										/>
									}
									
								</div>
								<div className="overflow-x-scroll">
									<table className="table-fixed">
										<thead>
											<tr>
												<th className="p-3 border-2">No</th>
												{this.state.data.label.map((label, i) => {
													return (
														<th className="p-3 border-2" key={i}>{label}</th>
													)
												})}
											</tr>
										</thead>
										<tbody>
											{this.state.data.data.map((item, n) => {
												return (
													<tr key={n.toString()} className="hover:bg-gray-100">
														<td className="text-xs text-center text-gray-500 p-3 border-2">{n + 1}</td>

														{Object.keys(item).map(key => {
															return (
																<td className="text-xs text-center text-gray-500 p-3 border-2">
                                                                    {['tsi', 'nominal'].includes(key) ? `Rp ${numeral(item[key]).format()}`: item[key]}
                                                                </td>
															)
														})}
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						: null}
                </div>
            </div>
        )
    }
}
